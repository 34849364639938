<template>
  <div
    class="relative min-h-screen px-2 py-10 bg-gray-100 sm:px-0"
    :class="getStartedSuccess ? 'flex items-center justify-center' : ''"
  >
    <div class="absolute bottom-0 z-0 w-full">
      <img src="@/assets/img/cities-and-cars-shuttlers.png" class="w-full auth-img">
    </div>
    <div
      class="absolute top-0 flex items-center justify-center w-full py-4 text-white bg-gray-type-1"
      style="height: 72px"
    >
      <app-logo />
    </div>

    <div
      class="relative z-10 w-full px-6 py-12 pt-8 pb-8 mx-auto mt-24 bg-white border rounded-xl sm:px-10"
      :class="getStartedSuccess ? 'max-w-lg' : 'max-w-xl'"
    >
      <template v-if="getStartedSuccess">
        <div class="w-full max-w-md">
          <img
            src="@/assets/img/email-sent.svg"
            class="block mx-auto text-center"
            alt="Check your email"
          />
          <div class="mt-6 text-sm font-medium text-center text-dark-type-4">
            Thank you for your interest in using Shuttlers for your business.
            Our business representative will reach out to you within 48 hours
            for next steps.
          </div>
          <a
            href="https://www.shuttlers.ng/"
            class="block w-56 h-12 py-4 mx-auto mt-12 font-bold text-center text-white rounded bg-green-type-1"
            >Back to home</a
          >
        </div>
      </template>
      <template v-else>
        <p class="text-lg font-bold text-center text-gray-type-2">
          Get started with Shuttlers for business
        </p>
        <p class="mt-2 text-xs font-medium text-center text-gray-type-3">
          Have an account?
          <router-link class="text-green-type-1" to="/login">Login</router-link>
        </p>
        <div class="flex items-center w-32 mx-auto mt-8">
          <div class="flex flex-col w-full" v-for="step in steps" :key="step">
            <div class="flex items-center">
              <div
                class="flex items-center justify-center flex-shrink-0 w-6 h-6 font-medium text-center border rounded-full text-xxs"
                :class="
                  step === currentStep
                    ? 'bg-green-type-1 text-white border-transparent'
                    : 'bg-gray-type-5 text-gray-type-6 border-gray-type-5'
                "
              >
                {{ step }}
              </div>
              <div
                class="relative w-20 h-1 bg-gray-type-5"
                v-if="step !== steps[steps.length - 1]"
              >
                <div
                  class="z-20 h-full transition-all duration-700 bg-gray-type-5"
                  :class="true ? 'w-full' : 'w-0'"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex items-start p-3 my-5 bg-red-200 rounded-md"
          v-if="errorMessage"
        >
          <div class="text-sm font-medium text-red-500">{{ errorMessage }}</div>
        </div>
        <form class="mt-8" @submit.prevent="getStarted" v-if="currentStep == 1">
          <p
            class="text-sm font-medium leading-relaxed text-center text-gray-type-2"
          >
            Provide your business details so we can get you the best package for
            your business
          </p>
          <div class="flex flex-wrap mt-4 -mx-3">
            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label class="text-xs font-medium" for="first-name"
                >First name</label
              >
              <input
                v-model="$v.getStartedForm.fname.$model"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                type="text"
                id="first-name"
              />
              <span
                class="text-xs font-bold text-red-type-1"
                v-if="$v.getStartedForm.fname.required.$invalid"
                >Please enter your first name</span
              >
            </div>
            <div class="w-full px-3 md:w-1/2">
              <label class="text-xs font-medium" for="last-name"
                >Last name</label
              >
              <input
                v-model="$v.getStartedForm.lname.$model"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                type="text"
                id="last-name"
              />
              <span
                class="text-xs font-bold text-red-type-1"
                v-if="$v.getStartedForm.lname.$invalid"
                >Please enter your last name</span
              >
            </div>
          </div>
          <div class="mt-6">
            <label class="text-xs font-medium text-gray-type-3" for="email">
              Email address</label
            >
            <div class="mt-2">
              <input
                v-model="$v.getStartedForm.email.$model"
                type="email"
                id="-email"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                placeholder="Use the email associated with your work domain"
              />
            </div>
            <span
              class="text-xs font-medium text-red-type-1"
              v-if="$v.getStartedForm.email.required.$invalid"
              >Please enter your email address</span
            >
          </div>
          <div class="flex flex-wrap mt-6 -mx-3">
            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="business-name"
                >Name of Company</label
              >
              <div class="mt-2">
                <input
                  v-model="$v.getStartedForm.corporate_name.$model"
                  type="text"
                  id="business-name"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                />
              </div>
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.corporate_name.required.$invalid"
                >Enter the name of your company</span
              >
            </div>
            <div class="w-full px-3 md:w-1/2">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="corporate_role"
                >Your role in company</label
              >
              <div class="mt-2">
                <input
                  v-model="$v.getStartedForm.corporate_role.$model"
                  type="text"
                  id="corporate_role"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                />
              </div>
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.corporate_role.required.$invalid"
                >What's your role in
                {{ getStartedForm.corporate_role || "the company" }}</span
              >
            </div>
          </div>
          <div class="flex flex-wrap mt-6 -mx-3">
            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="business-phone"
                >Corporate Phone Number</label
              >
              <div class="mt-2">
                <input
                  v-model="$v.getStartedForm.corporate_phone.$model"
                  type="tel"
                  id="business-phone"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                />
              </div>
              <div
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.corporate_phone.required.$invalid"
              >
                Enter the phone number of your company
              </div>
              <div
                class="text-xs font-medium text-red-type-1"
                v-if="
                  !$v.getStartedForm.corporate_phone.required.$invalid &&
                  $v.getStartedForm.corporate_phone.phoneNumber.$invalid
                "
              >
                Please enter a valid phone number
              </div>
            </div>
            <div class="w-full px-3 md:w-1/2">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="desired_start_date"
                >Desired Shuttle Start Date</label
              >
              <div class="mt-2">
                <datepicker
                  v-model="$v.getStartedForm.desired_start_date.$model"
                  id="desired_start_date"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                  :config="{
                    wrap: true,
                    altFormat: 'j M Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    minDate: 'today',
                  }"
                  placeholder="Select a date"
                ></datepicker>
              </div>
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.desired_start_date.required.$invalid"
                >Please select a desired start date</span
              >
            </div>
          </div>
          <div class="mt-6">
            <label
              class="text-xs font-medium text-gray-type-3"
              for="business-address"
              >Office location/address</label
            >
            <div class="mt-2">
              <input
                v-model="$v.getStartedForm.corporate_address.$model"
                type="text"
                id="business-address"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              />
            </div>
            <span
              class="text-xs font-medium text-red-type-1"
              v-if="$v.getStartedForm.corporate_address.required.$invalid"
              >Please enter your corporate address</span
            >
          </div>
          <div class="flex flex-wrap mt-6 -mx-3">
            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="business-resumption-time"
                >Resumption time</label
              >
              <div class="mt-2">
                <datepicker
                  v-model="$v.getStartedForm.resumption_time.$model"
                  id="business-resumption-time"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                  }"
                  placeholder="Select a date"
                ></datepicker>
              </div>
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.resumption_time.required.$invalid"
                >Please select business resumption time</span
              >
            </div>
            <div class="w-full px-3 md:w-1/2">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="business-closing-time"
                >Closing time</label
              >
              <div class="mt-2">
                <datepicker
                  id="business-closing-time"
                  v-model="$v.getStartedForm.closing_time.$model"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                  }"
                  placeholder="Select a date"
                ></datepicker>
              </div>
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.closing_time.required.$invalid"
                >Please select closing resumption time</span
              >
            </div>
          </div>
          <div class="flex flex-wrap mt-6 -mx-3">
            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="no_saff_members"
                >Number of staff members</label
              >
              <div class="mt-2">
                <input
                  v-model="$v.getStartedForm.num_staff_members.$model"
                  type="number"
                  id="no_saff_members"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                />
              </div>
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.num_staff_members.required.$invalid"
                >Please enter number of staff members</span
              >
            </div>
            <div class="w-full px-3 md:w-1/2">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="package-type"
                >Preferred package type</label
              >
              <div class="mt-2">
                <app-input-select
                  v-model="$v.getStartedForm.package_type.$model"
                  class="w-full px-2 py-1 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                  placeholder="Select a package"
                  :options="packageTypes"
                  :reduce="(opt) => opt.id"
                >
                </app-input-select>
              </div>
              <p
                class="mt-1 font-medium leading-snug text-xxs text-gray-type-3"
                v-if="getStartedForm.package_type"
              >
                {{ packageTypeExplanation[getStartedForm.package_type] }}
              </p>
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.getStartedForm.package_type.required.$invalid"
                >Please select a preferred package type</span
              >
            </div>
          </div>
          <div class="flex">
            <app-button
              type="submit"
              class="justify-center w-48 h-12 py-4 mt-8 ml-auto text-sm font-bold text-center text-white rounded sm:text-base bg-green-type-1"
              :loading="processing"
            >
              Get started
            </app-button>
          </div>
          <div class="w-full text-center mt-16">
            <p class="captcha-policy">This site is protected by reCAPTCHA and the Google
              <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and
              <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
          </div>
        </form>
        <form class="mt-8" v-if="currentStep == 2" @submit.prevent="register">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="first-name"
                >First name</label
              >
              <input
                v-model="$v.setupAdminFrom.fname.$model"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                type="text"
                id="first-name"
              />
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.setupAdminFrom.fname.required.$invalid"
                >Please enter your first name</span
              >
            </div>
            <div class="w-full px-3 md:w-1/2">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="last-name"
                >Last name</label
              >
              <input
                v-model="$v.setupAdminFrom.lname.$model"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                type="text"
                id="last-name"
              />
              <span
                class="text-xs font-medium text-red-type-1"
                v-if="$v.setupAdminFrom.lname.required.$invalid"
                >Please enter your last name</span
              >
            </div>
          </div>
          <div class="mt-6">
            <label class="text-xs font-medium text-gray-type-3" for="email"
              >Admin Email address</label
            >
            <div class="mt-2">
              <input
                v-model="$v.setupAdminFrom.admin_email.$model"
                type="email"
                id="email"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                placeholder="Use the email associated with your work domain"
              />
            </div>
            <span
              class="text-xs font-medium text-red-type-1"
              v-if="$v.setupAdminFrom.admin_email.required.$invalid"
              >Please enter your email address</span
            >
          </div>
          <div class="mt-6">
            <label
              class="text-xs font-medium text-gray-type-3"
              for="phone-number"
              >Admin Phone number</label
            >
            <div class="mt-2">
              <input
                v-model="$v.setupAdminFrom.admin_phone.$model"
                type="tel"
                id="phone-number"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              />
            </div>
            <div
              class="text-xs font-medium text-red-type-1"
              v-if="$v.setupAdminFrom.admin_phone.required.$invalid"
            >
              Please enter a phone number
            </div>
            <div
              class="text-xs font-medium text-red-type-1"
              v-if="
                !$v.setupAdminFrom.admin_phone.required.$invalid &&
                $v.setupAdminFrom.admin_phone.phoneNumber.$invalid
              "
            >
              Please enter a valid phone number
            </div>
          </div>
          <div class="mt-6">
            <label class="text-xs font-medium text-gray-type-3" for="password"
              >Password</label
            >
            <div class="relative mt-2">
              <input
                v-model="$v.setupAdminFrom.password.$model"
                :type="showPassword ? 'text' : 'password'"
                id="password"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-2 text-xs font-medium cursor-pointer text-green-type-1"
                @click="showPassword = !showPassword"
              >
                {{ showPassword ? "Hide" : "Show" }}
              </div>
            </div>
            <span
              class="text-xs font-medium text-red-type-1"
              v-if="$v.setupAdminFrom.password.required.$invalid"
              >Please enter a password</span
            >
          </div>
          <div
            class="flex px-4 py-5 mt-4 space-x-2 border-2 rounded border-gray-type-4"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 text-gray-type-9"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <p class="text-xs font-medium text-gray-type-8">
              Users registering using emails with your company domain will be
              automatically assigned to this company
            </p>
          </div>
          <div class="flex mt-6 space-x-6">
            <button
              type="button"
              @click="currentStep = currentStep - 1"
              class="w-full py-3 text-sm font-bold text-center bg-white border rounded text-green-type-1 sm:text-base border-green-type-1"
              :disabled="processing"
            >
              Back
            </button>
            <app-button
              type="submit"
              class="justify-center w-full py-3 text-sm font-bold text-center text-white rounded bg-green-type-1 sm:text-base"
              :loading="processing"
            >
              Create Account
            </app-button>
          </div>

          <div class="w-full text-center mt-16">
            <p class="captcha-policy">This site is protected by reCAPTCHA and the Google
              <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and
              <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
          </div>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import AppLogo from "@/components/AppLogo";
import { required, email } from "@vuelidate/validators";
import { useReCaptcha } from 'vue-recaptcha-v3'

const phoneNumber = (value) => {
  return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
    value
  );
};

export default {
  props: ["corporation_id", "token"],
  components: {
    AppLogo,
  },
  data() {
    return {
      showPassword: false,
      processing: false,
      errorMessage: "",
      currentStep: 1,
      steps: [1, 2],
      getStartedSuccess: false,
      getStartedForm: {
        fname: "",
        lname: "",
        email: "",
        corporate_name: "",
        corporate_phone: "",
        corporate_address: "",
        corporate_role: "",
        desired_start_date: "",
        resumption_time: "",
        closing_time: "",
        num_staff_members: "",
        package_type: "",
      },
      setupAdminFrom: {
        fname: "",
        lname: "",
        admin_email: "",
        admin_phone: "",
        password: "",
      },
      packageTypes: [
        { label: "Pay per seat", id: "pay_per_seat" },
        { label: "Charter", id: "charter" },
      ],
      captchaInitialized: false,
    };
  },
  setup() {
    const { instance, recaptchaLoaded, executeRecaptcha } = useReCaptcha();

    const recaptcha = async () => {
      await recaptchaLoaded();
    }

    const showBadge = () => {
      instance.value.showBadge();
    }

    const hideBadge = () => {
      instance.value.hideBadge();
    }
    const execute = (action) => {
      return executeRecaptcha(action);
    }
    return {
      recaptcha,
      recaptchaInstance: {...instance.value, hideBadge, showBadge, execute},
    }
  },
  async mounted() {
    try {
      await this.recaptcha();
      this.captchaInitialized = true;
    } catch (e) {
      console.error(e);
    }
  },
  validations() {
    return {
      getStartedForm: {
        fname: {
          required,
        },
        lname: {
          required,
        },
        email: {
          required,
          email,
        },
        corporate_name: {
          required,
        },
        corporate_phone: {
          required,
          phoneNumber,
        },
        corporate_address: {
          required,
        },
        corporate_role: {
          required,
        },
        desired_start_date: {
          required,
        },
        resumption_time: {
          required,
        },
        closing_time: {
          required,
        },
        num_staff_members: {
          required,
        },
        package_type: {
          required,
        },
      },
      setupAdminFrom: {
        fname: {
          required,
        },
        lname: {
          required,
        },
        admin_email: {
          required,
          email,
        },
        admin_phone: {
          required,
          phoneNumber,
        },
        password: {
          required,
        },
      },
    };
  },
  computed: {
    packageTypeExplanation() {
      return {
        pay_per_seat: "Paying for only seats in available public routes",
        charter: "Paying for the entire bus to create private routes",
      };
    },
    hasRecaptchaKey() {
      return process.env.VUE_APP_RECAPTCHA_ENABLED === 'true' && !!process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    }
  },
  methods: {
    async getCaptchaToken() {
      if(this.hasRecaptchaKey){
        try {
          const token = await this.recaptchaInstance.execute('business_registration');
          return token;
        } catch (error) {
          return '';
        }
      } else {
        return '';
      }
    },
    async verifyCaptcha() {
      const token = await this.getCaptchaToken();
      if(this.captchaInitialized && token) {
        return this.$http.post("/token/captcha/validate", {token});
      } else {
        return Promise.resolve({
          data:{
            success: !this.hasRecaptchaKey
          }
        });
      }
    },
    register() {
      this.$v.form.$touch();
      if (this.processing || this.$v.form.$error) {
        return;
      }
      this.processing = true;
      this.errorMessage = null;
      const payload = {...this.form};
      this.verifyCaptcha()
          .then((res) => {
            if(res.data.success) {
              return this.$store.dispatch("auth/register", payload);
            } else {
              this.rejectCaptchaVerification('Captcha Verification Failed!');
            }
          })
          .then(async () => {
            this.$eventTracker.logEvent(this.$eventTracker.trackingEvent.REGISTRATION_COMPLETED,
                {email: this.getStartedForm.email,
                  adminEmail: this.setupAdminFrom.admin_email});
            await this.$router.push({
              name: "dashboard.home",
            });
          })
          .catch((err) => {
            if (err.response && err.response.data.message) {
              this.errorMessage = err.response.data.message;
            } else {
              this.errorMessage = "Oops! An error occurred, please try again.";
            }
          })
          .finally(() => (this.processing = false));
    },
    rejectCaptchaVerification(message) {
      return Promise.reject({
        response: {
          data: {
            message
          }
        }
      })
    },
    getStarted() {
      this.$v.getStartedForm.$touch();
      if (this.processing || this.$v.getStartedForm.$errors.length) {
        return;
      }
      const resumptionTime = this.getStartedForm.resumption_time.split(":")[0];
      const closingTime = this.getStartedForm.closing_time.split(":")[0];

      if (resumptionTime > closingTime) {
        this.$toast.error("Closing time should be later than resumption time");
        return;
      }

      this.processing = true;
      this.errorMessage = null;
      this.getStartedSuccess = false;

      const payload = {
        ...this.getStartedForm,
        num_staff_members: parseInt(this.getStartedForm.num_staff_members),
      };
      this.verifyCaptcha()
          .then((res) => {
            if(res.data.success) {
              return this.$http.post("/corporates/interest", payload);
            } else {
              return this.rejectCaptchaVerification('Captcha Verification Failed!');
            }
          })
          .then(() => {
            this.getStartedSuccess = true;
            this.$eventTracker.logEvent(this.$eventTracker.trackingEvent.REGISTRATION_STARTED,
              {email: this.getStartedForm.email});
          })
          .catch((err) => {
            if (err.response && err.response.data.message) {
              this.errorMessage = err.response.data.message;
            } else {
              this.errorMessage = "Oops! An error occurred, please try again.";
            }
            this.getStartedSuccess = false;
            this.$toast.error("An error occurred, please try again.");
        })
        .finally(() => (this.processing = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.captcha-policy {
  @apply sm:max-w-sm max-w-3xl mx-auto font-normal text-gray-type-8 text-xxs;
  a {
    @apply text-green-700 font-medium;
    text-decoration: none;
  }
}
</style>